import React from "react";
const handleClosePopup = (e)=>{
    e.preventDefault();
    let el = document.getElementById("popupEl");
    el.style.opacity = 0;
    setTimeout(()=>{
        el.style.display = "none";},500);

    setTimeout(()=>{
        el.style.display = "block";
        el.style.opacity = 1;
    },15000)
}
setTimeout(()=>{
    let el = document.getElementById("popupEl");
    el.style.opacity = 0;
    el.style.display = "block";
    el.style.opacity = 1;
},10000);
export const Popup = () => (

    <>
        <div className="overlay" id="popupEl">
            <div className="popup">
                <div className="popup__header">
                    <h4>{'هل تعلم أن..'}</h4>
                    <button onClick={handleClosePopup} className='close-btn'>&times;</button>
                </div>
                <div className="popup__body">
                    <p className="popup__content">
                        <img src="https://dkycadmin.ksakyc.click/uploads/logo/1677640603eAC7ZJ.png" alt=""/>
                        <b>دقة</b>&nbsp;
                        {'توفر لك التقنيات اللازمة لمعرفة مستوى رضا عملائك عن خدماتك باستخدام تقنيات الذكاء الاصطناعي والتعرف على الوجه!'}
                    </p>
                    <ul className="social_links">

                        <li>
                            <a href=""><i className="fa fa-thin fa-globe "></i> {'ويبسايت'}</a>
                        </li>
                        <li>
                            <a href=""><i className="fa fas far fab fa-whatsapp fa-solid"></i> {'واتساب'}</a>
                        </li>
                        <li>
                            <a href=""><i className="fa fab fa-twitter"></i> {'تويتر'}</a>
                        </li>
                        <li>
                            <a href=""><i className="fa fab fa-linkedin"></i> {'لينكدإن'}</a>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    </>
);
