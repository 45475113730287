// const URL_EMOTION_RECOGNITION_MODEL: string =
//   "https://raw.githubusercontent.com/clementreiffers/emotion-recognition-website/main/resnet50js_ferplus/model.json";
const BASE_URL = 'https://deqa.ai.omarius.net'
const URL_EMOTION_RECOGNITION_MODEL: string = BASE_URL+'/model.json'

const URL_JS_DELIVR: string =
    BASE_URL+"/mediapipe/face_detection/";

const URL_GITHUB_CODE_SOURCE: string =
  "https://github.com/clementreiffers/emotion-recognition-website-react";

export { URL_EMOTION_RECOGNITION_MODEL, URL_JS_DELIVR, URL_GITHUB_CODE_SOURCE };
