const EMOTIONS = [
  "😡 Angry : ",
  "🤮 Disgust : ",
  "😨 Fear : ",
  "😄 Happy : ",
  "😐 Neutral : ",
  "😭 Sad : ",
  "😯 Surprise : ",
];

const NO_MODEL: string = "❌ model not loaded yet";

const PRED_RESIZE_SHAPE: number[] = [1, 80, 80, 3];

const RESIZE_SHAPE: number[] = [80, 80];

export { EMOTIONS, NO_MODEL, PRED_RESIZE_SHAPE, RESIZE_SHAPE };
